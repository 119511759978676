import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { trackingEvents, ROUTES_OWNER_PANEL, ROUTES_AUTH } from '@constants';
import { ReactComponent as InfoCircle } from 'assets/svgs/import/code/InfoCircle.svg';
import { Alert, Badge, Button, Card, CardFlag, CardFlagEnum } from 'components';
import { useTracking, useLayout } from 'contexts';

import { useGetOwnerSubscription, useCancelOwnerSubscription } from 'services';
import {
    onConfirmDangerMessage,
    onErrorMessage,
    onSuccessMessage,
} from 'helpers';
import { convertDateENToBR, convertFloatToBR } from 'utils';

import subscriptionSuccess from 'assets/svgs/illustration/SubscriptionSuccess.svg';
import subscriptionCanceled from 'assets/svgs/illustration/SubscriptionCanceled.svg';

import { LabelValue } from './components';

import {
    SubscriptionShowWrapper,
    SubscriptionShowTitleBox,
    SubscriptionShowTitle,
    SubscriptionShowSubtitle,
    SubscriptionShowContent,
    SubscriptionShowContentBoxes,
    SubscriptionShowBox,
    SubscriptionShowBoxTitle,
    SubscriptionShowBoxContent,
    SubscriptionShowBoxContentCard,
    SubscriptionShowImage,
    SubscriptionShowButtons,
} from './SubscriptionShow.style';

import type { PanelOwnerSubscriptionShowProps } from './SubscriptionShow.type';

export const PanelOwnerSubscriptionShow = ({
    onClickNewSubscription,
}: PanelOwnerSubscriptionShowProps) => {
    const { t } = useTranslation();
    const { initLayout } = useLayout();

    const { trackEvent } = useTracking();

    useEffect(() => {
        initLayout('Assinatura', [
            {
                label: 'Seleção de Conta',
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: 'Assinatura Atual',
                link: ROUTES_OWNER_PANEL.SUBSCRIPTION.fullPath,
            },
        ]);

        trackEvent(trackingEvents.subscriptionShowPageView);
    }, []);

    const { data, isLoading, refetch } = useGetOwnerSubscription();
    const { data: subscription } = data || { subscription: null };

    const { mutateAsync, isLoading: isLoadingCancel } =
        useCancelOwnerSubscription();

    const onHandleDelete = async () => {
        trackEvent(trackingEvents.subscriptionShowCancelClick);

        const confirm = await onConfirmDangerMessage(
            t('subscriptionShowCancelConfirmTitle'),
            t('subscriptionShowCancelConfirmMessage'),
            t('subscriptionShowCancelConfirmButtonDelete'),
            t('subscriptionShowCancelConfirmButtonCancel')
        );

        if (confirm) {
            const result = await mutateAsync();

            if (result.success) {
                onSuccessMessage(
                    t('subscriptionShowCancelSuccessTitle'),
                    t('subscriptionShowCancelSuccessMessage')
                );

                trackEvent(trackingEvents.subscriptionShowCancelSuccess);

                refetch();
            } else {
                onErrorMessage(
                    t('subscriptionShowCancelErrorTitle'),
                    t('subscriptionShowCancelErrorMessage')
                );

                trackEvent(trackingEvents.subscriptionShowCancelError);
            }
        }
    };

    const badgeObject: Record<string, ReactNode> = {
        ACTIVE: (
            <Badge size="small" model="light" variation="success">
                {t('subscriptionShowStatusActive')}
            </Badge>
        ),
        CANCELED: (
            <Badge size="small" model="light" variation="danger">
                {t('subscriptionShowStatusCanceled')}
            </Badge>
        ),
        PENDING: (
            <Badge size="small" model="light" variation="warning">
                {t('subscriptionShowStatusPending')}
            </Badge>
        ),
        EXPIRED: (
            <Badge size="small" model="light" variation="danger">
                {t('subscriptionShowStatusExpired')}
            </Badge>
        ),
    };

    const onClickNewSubscriptionLocal = () => {
        trackEvent(trackingEvents.subscriptionShowRenewClick);
        onClickNewSubscription();
    };

    return (
        <Card>
            <SubscriptionShowWrapper>
                <SubscriptionShowTitleBox>
                    <SubscriptionShowTitle>
                        {t('subscriptionShowCurrentPlanTitle')}
                    </SubscriptionShowTitle>
                    <SubscriptionShowSubtitle>
                        {t('subscriptionShowCurrentPlanSubtitle', {
                            planName: subscription?.plan?.name,
                        })}
                    </SubscriptionShowSubtitle>
                </SubscriptionShowTitleBox>

                {subscription?.status === 'CANCELED' && (
                    <Alert
                        model="light"
                        icon={<InfoCircle />}
                        variation="danger"
                        title={t('subscriptionShowCanceledTitle')}
                        description={t('subscriptionShowCanceledMessage', {
                            date: convertDateENToBR(
                                subscription?.nextPaymentDate
                            ),
                        })}
                    />
                )}

                <SubscriptionShowContent>
                    <SubscriptionShowContentBoxes>
                        <SubscriptionShowBox>
                            <SubscriptionShowBoxTitle>
                                {t('subscriptionShowPlanDetailsTitle')}
                            </SubscriptionShowBoxTitle>

                            <SubscriptionShowBoxContent>
                                <LabelValue
                                    isLoading={isLoading}
                                    label={t(
                                        'subscriptionShowSelectedPlanLabel'
                                    )}
                                    value={
                                        <Badge model="light">
                                            {subscription?.plan?.name}
                                        </Badge>
                                    }
                                />

                                <LabelValue
                                    isLoading={isLoading}
                                    label={t('subscriptionShowAmountLabel')}
                                    value={`${convertFloatToBR(
                                        subscription?.amount,
                                        2,
                                        'R$ '
                                    )} / ${t('subscriptionShowMonthly')}`}
                                />
                            </SubscriptionShowBoxContent>
                        </SubscriptionShowBox>

                        <SubscriptionShowBox>
                            <SubscriptionShowBoxTitle>
                                {t('subscriptionShowPaymentDetailsTitle')}
                            </SubscriptionShowBoxTitle>

                            {subscription?.gatewayPaymentCard ? (
                                <SubscriptionShowBoxContent>
                                    <LabelValue
                                        isLoading={isLoading}
                                        label={t('subscriptionShowCardLabel')}
                                        value={
                                            <SubscriptionShowBoxContentCard>
                                                {
                                                    subscription
                                                        ?.gatewayPaymentCard
                                                        ?.maskedNumber
                                                }
                                                <CardFlag
                                                    flag={
                                                        subscription
                                                            ?.gatewayPaymentCard
                                                            ?.flag as CardFlagEnum
                                                    }
                                                    size="36px"
                                                />
                                            </SubscriptionShowBoxContentCard>
                                        }
                                    />

                                    <LabelValue
                                        isLoading={isLoading}
                                        label={t(
                                            'subscriptionShowValidityLabel'
                                        )}
                                        value={`${subscription?.gatewayPaymentCard?.expirationMonth}/${subscription?.gatewayPaymentCard?.expirationYear}`}
                                    />
                                </SubscriptionShowBoxContent>
                            ) : (
                                <SubscriptionShowBoxContent>
                                    <LabelValue
                                        isLoading={isLoading}
                                        label={t('subscriptionShowBoletoLabel')}
                                        value={t('subscriptionShowBoletoMessage')}
                                    />
                                </SubscriptionShowBoxContent>
                            )}
                        </SubscriptionShowBox>

                        <SubscriptionShowBox>
                            <SubscriptionShowBoxTitle>
                                {t('subscriptionShowSubscriptionDetailsTitle')}
                            </SubscriptionShowBoxTitle>

                            <SubscriptionShowBoxContent>
                                <LabelValue
                                    isLoading={isLoading}
                                    label={t('subscriptionShowStartDateLabel')}
                                    value={convertDateENToBR(
                                        subscription?.startDate
                                    )}
                                />

                                <LabelValue
                                    isLoading={isLoading}
                                    label={t('subscriptionShowStatusLabel')}
                                    value={
                                        badgeObject[
                                            subscription?.status || 'PENDING'
                                        ]
                                    }
                                />

                                {subscription?.status === 'ACTIVE' ? (
                                    <LabelValue
                                        isLoading={isLoading}
                                        label={t(
                                            'subscriptionShowNextBillingDateLabel'
                                        )}
                                        value={convertDateENToBR(
                                            subscription?.nextPaymentDate
                                        )}
                                    />
                                ) : (
                                    <LabelValue
                                        isLoading={isLoading}
                                        label={t(
                                            'subscriptionShowEndDateLabel'
                                        )}
                                        value={convertDateENToBR(
                                            subscription?.nextPaymentDate
                                        )}
                                    />
                                )}
                            </SubscriptionShowBoxContent>
                        </SubscriptionShowBox>
                    </SubscriptionShowContentBoxes>

                    <SubscriptionShowImage
                        src={
                            subscription?.status === 'CANCELED'
                                ? subscriptionCanceled
                                : subscriptionSuccess
                        }
                        alt={t('subscriptionShowSuccessImageAlt')}
                    />
                </SubscriptionShowContent>

                <SubscriptionShowButtons>
                    {subscription?.status !== 'CANCELED' ? (
                        <Button
                            isLoading={isLoadingCancel}
                            variation="danger"
                            model="light"
                            onClick={onHandleDelete}
                        >
                            {t('subscriptionShowCancelSubscriptionButton')}
                        </Button>
                    ) : (
                        <Button
                            model="light"
                            onClick={onClickNewSubscriptionLocal}
                        >
                            {t('subscriptionShowNewSubscriptionButton')}
                        </Button>
                    )}
                </SubscriptionShowButtons>
            </SubscriptionShowWrapper>
        </Card>
    );
};
