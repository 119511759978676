import { useTheme } from 'styled-components';

import { FieldSummaryImage } from './SvgMap.style';

import { SvgMapInterface } from './SvgMap.type';

export const SvgMap = ({
    width = '100px',
    height = '100px',
    coordinates,
    padding = 10,
}: SvgMapInterface) => {
    const theme = useTheme();

    if (!coordinates) {
        return null;
    }

    let parsedCoords;

    try {
        parsedCoords = JSON.parse(coordinates);
        if (!Array.isArray(parsedCoords)) throw new Error();
    } catch {
        return (
            <FieldSummaryImage
                height={height}
                width={width}
            />
        );
    }

    if (parsedCoords.length === 0) {
        return (
            <FieldSummaryImage
                height={height}
                width={width}
            />
        );
    }

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    parsedCoords.forEach(({ latitude, longitude }) => {
        if (latitude < minX) minX = latitude;
        if (longitude < minY) minY = longitude;
        if (latitude > maxX) maxX = latitude;
        if (longitude > maxY) maxY = longitude;
    });

    const latDiff = maxX - minX;
    const longDiff = maxY - minY;

    const safeLatDiff = latDiff === 0 ? 1 : latDiff;
    const safeLongDiff = longDiff === 0 ? 1 : longDiff;

    const availableWidth = 100 - 2 * padding;
    const availableHeight = 100 - 2 * padding;

    const multiplier = Math.min(
        availableWidth / safeLatDiff,
        availableHeight / safeLongDiff
    );

    const scaledWidth = latDiff * multiplier;
    const scaledHeight = longDiff * multiplier;

    const offsetX = padding + (availableWidth - scaledWidth) / 2;
    const offsetY = padding + (availableHeight - scaledHeight) / 2;

    let points = '';

    parsedCoords.forEach(({ latitude, longitude }) => {
        const x = (latitude - minX) * multiplier + offsetX;
        const y = 100 - ((longitude - minY) * multiplier + offsetY);
        points += `${x},${y} `;
    });

    const viewBox = `0 0 100 100`;

    return (
        <svg height={height} width={width} viewBox={viewBox}>
            <polygon
                points={points.trim()}
                stroke={theme.colors.secondary700}
                strokeWidth="2"
                fill={theme.colors.secondary200}
            />
        </svg>
    );
};
