import { Button } from 'components';

import { MenuWrap } from './styles';

import type { MenuInterface } from './interface';

const Menu = ({ menus }: MenuInterface) => (
    <MenuWrap>
        {menus?.map(({ active, label, link }) => (
            <li key={label}>
                <Button
                    variation="white"
                    href={link}
                    className={active ? 'active' : ''}
                >
                    {label}
                </Button>
            </li>
        ))}
    </MenuWrap>
);

export default Menu;
