import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    bottom: ${props => props.theme.spacing[4]};
    left: 0;
    right: 0;

    z-index: 1000;  

`;

export const ScrollContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${props => props.theme.spacing[4]};

    overflow-x: auto;
    scrollbar-width: none;  
    -ms-overflow-style: none;
    

    & > :first-child {
        margin-left: ${props => props.theme.spacing[4]};
    }

    & > :last-child {
        margin-right: ${props => props.theme.spacing[4]};
    }
`;



export const ScrollButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    border: none;
    border-radius: ${props => props.theme.borderRadius.large};
    cursor: pointer;
    z-index: 1;

    height: 2rem;
    width: 2rem;
    background-color: ${props => props.theme.colors.secondary500};
    color: ${props => props.theme.colors.active.primary};

    bottom: 6.2rem;

    &.prev {
        right: calc(${props => props.theme.spacing[4]} + 2.5rem);
    }

    &.next {
        right: ${props => props.theme.spacing[4]};
    }
`;