import styled from 'styled-components';

export const SignInWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: ${(props) => props.theme.colors.base.white};

    form {
        max-width: 500px;
        width: 100%;
        padding: ${(props) => props.theme.spacing[15]};

        h1 {
            font-size: ${(props) => props.theme.fontSizes[1]};
            color: ${(props) => props.theme.colors.text.dark};
            font-weight: 700;
            text-align: center;

            margin-bottom: ${(props) => props.theme.spacing[3]};
        }

        h2 {
            font-size: ${(props) => props.theme.fontSizes[4]};
            color: ${(props) => props.theme.colors.text.gray400};
            font-weight: 400;
            text-align: center;

            margin-bottom: ${(props) => props.theme.spacing[10]};
        }
    }
`;
