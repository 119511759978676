import styled from 'styled-components';

export const FieldSummaryWrap = styled.button.attrs({ type: 'button' })<{ isSelected: boolean }>`
    display: flex;
    align-items: center;
    position: relative;

    padding: ${(props) => props.theme.spacing[4]};
    border-style: solid;
    border-width: ${(props) => props.theme.borderSize.default};
    border-color: ${(props) => props.isSelected ? props.theme.colors.secondary800 : props.theme.colors.gray300};
    border-radius: ${(props) => props.theme.borderRadius.default};
    background-color: ${(props) => props.isSelected ? props.theme.colors.secondary100 : props.theme.colors.gray000};

    min-width: 15rem;
`;

export const FieldSummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: ${(props) => props.theme.spacing[4]};

    gap: ${(props) => props.theme.spacing[2]};
`;

export const FieldSummaryTitle = styled.span<{ isSelected: boolean }>`
    font-size: ${(props) => props.theme.fontSizes[7]};
    line-height: 100%;
    color: ${(props) => props.isSelected ? props.theme.colors.primary1000 : props.theme.colors.text.dark};
    font-weight: 700;
    text-align: left;
`;

export const FieldSummaryQuantity = styled.span<{ isSelected: boolean }>`
    font-size: ${(props) => props.theme.fontSizes[8]};
    line-height: 100%;
    color: ${(props) => props.isSelected ? props.theme.colors.primary1000 : props.theme.colors.text.muted};
    font-weight: 400;
`;
