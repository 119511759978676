import { TooltipWrap } from './styles';

import type { TooltipInterface } from './interface';

const Tooltip = ({
    children,
    label,
    position = 'right',
    startContent = 0,
    style,
}: TooltipInterface) => (
    <TooltipWrap position={position} style={style} startContent={startContent}>
        {children}
        <span>{label}</span>
    </TooltipWrap>
);

export default Tooltip;
