import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { ROUTES_OWNER_PANEL } from '@constants/routes';

import Logo from 'assets/images/logo_icon_two.png';
import { ReactComponent as MenuIcon } from 'assets/svgs/icons/Menu.svg';
import { ReactComponent as Article } from 'assets/svgs/icons/Article.svg';

import { useAuth, useLayout} from 'contexts'

import Menu from './Menu';
import ResponsiveMenu from './ResponsiveMenu';
import Title from './Title';
import Profile from './Profile';

import { HeaderWrap } from './styles';


import type { HeaderInterface } from './interface';

const Header = ({
    isOpenResponsiveSidebar,
    onOpenResponsiveSidebar,
    isOpenResponsiveMenu,
    onOpenResponsiveMenu,
}: HeaderInterface) => {
    const { pathname } = useLocation();

    const {t} = useTranslation();

    const { user, idAccess } = useAuth();
    const { breadcrumbs, title} = useLayout();

    const userAccess = user?.usersAccess?.find((access) => access.id === idAccess);

    const menus = [
        {
            active: ROUTES_OWNER_PANEL.DASHBOARD.fullPath === pathname,
            label: t('routesDashboad'),
            link: ROUTES_OWNER_PANEL.DASHBOARD.fullPath,
        },
        {
            active: ROUTES_OWNER_PANEL.FIELD.fullPath === pathname,
            label: t('routesField'),
            link: ROUTES_OWNER_PANEL.FIELD.fullPath,
        },
        {
            active: ROUTES_OWNER_PANEL.ACTIVITY.fullPath === pathname,
            label: t('routesActivity'),
            link: ROUTES_OWNER_PANEL.ACTIVITY.fullPath,
        },
        {
            active: ROUTES_OWNER_PANEL.CROP.fullPath === pathname,
            label: t('routesCrop'),
            link: ROUTES_OWNER_PANEL.CROP.fullPath,
        },
        {
            active: ROUTES_OWNER_PANEL.FINANCIAL.fullPath === pathname,
            label: t('routesFinancial'),
            link: ROUTES_OWNER_PANEL.FINANCIAL.fullPath,
        },
        {
            active: ROUTES_OWNER_PANEL.SUBSCRIPTION.fullPath === pathname,
            label: t('routesSubscription'),
            link: ROUTES_OWNER_PANEL.SUBSCRIPTION.fullPath,
        },
    ];

    return (
        <>
            <HeaderWrap>
                <button
                    aria-label='Menu'
                    className="bars-responsive-sidebar"
                    type="button"
                    onClick={() =>
                        onOpenResponsiveSidebar(!isOpenResponsiveSidebar)
                    }
                >
                    <MenuIcon />
                </button>

                <Link className="logo" to="/">
                    <img
                        alt="logo do agtor, é um círculo de cor amarelo com quatro sementes dentro na cor branca"
                        src={Logo}
                    />
                </Link>

                <Title
                    className="header-title"
                    title={title}
                    breadcrumbs={breadcrumbs}
                />

                <Menu menus={menus} />

                <Profile
                    name={user?.name || ''}
                    type={userAccess?.type || ''}
                />

                <button
                    aria-label='Menu'
                    className="bars-responsive-menu"
                    type="button"
                    onClick={() => onOpenResponsiveMenu(!isOpenResponsiveMenu)}
                >
                    <Article />
                </button>
            </HeaderWrap>
            <ResponsiveMenu
                menus={menus}
                isOpenResponsiveMenu={isOpenResponsiveMenu}
            />
        </>
    );
};
export default Header;
