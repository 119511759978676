import styled from 'styled-components';

import fieldImageUrl from 'assets/icons/field.png';


export const FieldSummaryImage = styled.img.attrs({
    src: fieldImageUrl,
    alt: 'invalid coordinates'
})`
    border-radius: ${(props) => props.theme.borderRadius.default};
`;