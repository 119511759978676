import { FormEvent, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { QUERIES } from '@constants';
import { IAActivity, IAField, EAActivityOperation } from '@types';

import {
    Button,
    DateTimePicker,
    Drawer,
    DrawerFormHeader,
    DrawerFormFooter,
    Select,
    InputText,
    InputNumber,
} from 'components';
import { useAuth } from 'contexts'

import { onValidationSchemaObject } from 'helpers';

import {
    useGetOwnerCropList,
    useGetOwnerActivityDetail,
    useGetOwnerInputList,
    useGetOwnerWarehouseList,
    useGetOwnerPatrimonyList,
    useUpdateOwnerActivity,
    useCreateOwnerActivity,
    useGetOwnerFieldList,
} from 'services';

import {
    ActivityFormInputField,
    ActivityFormFieldField,
    ActivityFormPatrimonyField,
} from './components';

import {
    ActivityFormContent,
    ActivityFormInputsWrap,
} from './ActivityForm.style';

import type { ActivityFormProps } from './ActivityForm.type';

export const ActivityForm = ({
    id,
    isOpen,
    onOpen,
    idAccess,
}: ActivityFormProps) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { account } = useAuth();

    const { data: dataGetted, isLoading } = useGetOwnerActivityDetail(id);

    const { data: dataWarehouse, isLoading: isLoadingWarehouse } =
        useGetOwnerWarehouseList({ filters: { idAccess } });
    const { data: dataInput, isLoading: isLoadingInput } = useGetOwnerInputList(
        {
            filters: { idAccess },
        }
    );

    const { data: dataPatrimonies, isLoading: isLoadingPatrimonies } =
        useGetOwnerPatrimonyList({
            filters: { idAccess },
        });

    const { data: dataFields, isLoading: isLoadingFields } =
        useGetOwnerFieldList({
            filters: { idAccess },
        });

    const { data: dataCrop, isLoading: isLoadingCrop } = useGetOwnerCropList({
        filters: { idAccess },
    });

    const { mutateAsync: onUpdateAsync, isLoading: isLoadingUpdate } =
        useUpdateOwnerActivity(id || '');
    const { mutateAsync: onCreateAsync } = useCreateOwnerActivity();

    const [isOpenFieldsDrawer, setIsOpenFieldsDrawer] = useState(false);
    const [isOpenPatrimoniesDrawer, setIsOpenPatrimoniesDrawer] =
        useState(false);
    const [isOpenInputsDrawer, setIsOpenInputsDrawer] = useState(false);

    const [data, setData] = useState<Partial<IAActivity>>({});

    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);

    const onCloseDrawer = () => {
        onOpen(false);
        setIsOpenFieldsDrawer(false);
        setIsOpenPatrimoniesDrawer(false);
        setIsOpenInputsDrawer(false);

        setErrors({});
        setListenErrors(false);
    };

    const onChangeData = (
        key: keyof IAActivity,
        value: string | number | undefined
    ) => {

        const newValue = {
            ...data,
            [key]: value,
        } as IAActivity;

        setData(newValue);
    };

    const onChangeFields = (fields: IAField[]) => {
        const totalArea = fields.reduce((acc, field) => acc + field.size, 0);

        setData({
            ...data,
            fields,
            areaWorked: totalArea,
        });
    };

    const getCommonInputStringProps = (key: keyof IAActivity) => ({
        value: data?.[key] as string,
        onChange: (newValue?: string) => onChangeData(key, newValue),
        error: (listenErrors && errors?.[key]) || undefined,
        name: key,
        isLoading,
    });

    const getCommonInputNumberProps = (key: keyof IAActivity) => ({
        value: data?.[key] as number,
        onChange: (newValue: number) => onChangeData(key, newValue),
        error: (listenErrors && errors?.[key]) || undefined,
        name: key,
        isLoading,
    });

    useEffect(() => {
        if (dataGetted && id) {
            setData({
                ...dataGetted,
            });
        } else {
            setData({
                fields: [],
                inputs: [],
                patrimonies: [],
                observation: '',
                dateInitial: '',
                dateEnd: '',
                areaWorked: 0,
                warehousesIds: undefined,
            });
        }
    }, [dataGetted, id]);

    const shapeSchema = {
        dateInitial: Yup.string().required(t('activityFormRequiredField')),
        dateEnd: Yup.string().required(t('activityFormRequiredField')),
        areaWorked: Yup.number().required(t('activityFormRequiredField')),
        warehousesIds: Yup.string()
            .nullable()
            .required(t('activityFormRequiredField')),
        cropsIds: Yup.string()
            .nullable()
            .required(t('activityFormRequiredField')),
        operation: Yup.string().required(t('activityFormRequiredField')),
    };

    useEffect(() => {
        const validateErrors = async () => {
            const result = await onValidationSchemaObject(shapeSchema, data);

            if (!result.isValid) {
                setErrors(result.errors);
            }
        };

        if (listenErrors) {
            validateErrors();
        }
    }, [data, listenErrors]);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const newData = {
            ...data,
            fields: data?.fields ? data?.fields?.map(field => ({ fieldsIds: field.id } as IAField)) : [],
        };

        const result = await onValidationSchemaObject(shapeSchema, newData);

        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);
        } else {
            setListenErrors(false);

            let res = null;

            if (id) {
                res = await onUpdateAsync(newData);
            } else {
                res = await onCreateAsync(newData);
            }

            if (res) {
                onCloseDrawer();

                queryClient.invalidateQueries([
                    QUERIES.OWNER.ACTIVITY.module,
                    idAccess,
                ]);

                if (id) {
                    queryClient.invalidateQueries([
                        QUERIES.OWNER.ACTIVITY.module,
                        id,
                    ]);
                }
            }
        }
    };

    return (
        <Drawer isOpen={isOpen} onOpen={onCloseDrawer}>
            <form onSubmit={onSubmit}>
                <DrawerFormHeader title={id ? t('activityFormEditTitle') : t('activityFormNewTitle')} />

                <ActivityFormContent>
                    <ActivityFormInputsWrap>
                        <Select
                            {...getCommonInputStringProps('operation')}
                            label={t('activityFormOperationLabel')}
                            placeholder={t('activityFormOperationPlaceholder')}
                            options={Object.values(EAActivityOperation).map(
                                (operation) => ({
                                    label: t(
                                        `ACTIVITY_OPERATION_ENUM_${operation}`
                                    ),
                                    value: operation,
                                })
                            )}
                            isRequired
                        />

                        <Select
                            {...getCommonInputStringProps('cropsIds')}
                            label={t('activityFormCropLabel')}
                            placeholder={t('activityFormCropPlaceholder')}
                            options={
                                dataCrop?.map((crop) => ({
                                    label: crop.name,
                                    value: `${crop.id}`,
                                })) || []
                            }
                            isLoading={isLoadingCrop || isLoading}
                            isRequired
                        />

                        <DateTimePicker
                            {...getCommonInputStringProps('dateInitial')}
                            label={t('activityFormDateInitialLabel')}
                            placeholder={t(
                                'activityFormDateInitialPlaceholder'
                            )}
                            isRequired
                        />

                        <DateTimePicker
                            {...getCommonInputStringProps('dateEnd')}
                            label={t('activityFormDateEndLabel')}
                            placeholder={t('activityFormDateEndPlaceholder')}
                            isRequired
                        />

                        <ActivityFormFieldField
                            items={data?.fields || []}
                            dataFields={dataFields?.filter(field => field.isActive) || []}
                            isLoading={isLoading || isLoadingFields}
                            isOpen={isOpenFieldsDrawer}
                            onOpen={setIsOpenFieldsDrawer}
                            onSave={onChangeFields}
                        />

                        <InputNumber
                            {...getCommonInputNumberProps('areaWorked')}
                            label={t('activityFormAreaWorkedLabel')}
                            placeholder={t('activityFormAreaWorkedPlaceholder')}
                            suffix={account?.unitySize === 'METER' ? ' m²' : ' ha'}
                            stepSize={1}
                        />

                        <Select
                            {...getCommonInputStringProps('warehousesIds')}
                            isRequired
                            label={t('activityFormWarehouseLabel')}
                            placeholder={t('activityFormWarehousePlaceholder')}
                            options={
                                dataWarehouse?.map((input) => ({
                                    label: input.name,
                                    value: `${input.id}`,
                                })) || []
                            }
                            isLoading={isLoadingWarehouse || isLoading}
                        />

                        <ActivityFormPatrimonyField
                            items={data?.patrimonies || []}
                            dataPatrimonies={dataPatrimonies?.filter(patrimony => ['MACHINE', 'PIVO', 'SILO', 'VEHICLE', 'OTHERS'].includes(patrimony?.type)) || []}
                            isLoading={isLoading || isLoadingPatrimonies}
                            isOpen={isOpenPatrimoniesDrawer}
                            onOpen={setIsOpenPatrimoniesDrawer}
                            onSave={(patrimonies) => 
                                setData({
                                    ...data,
                                    patrimonies,
                                })
                            }
                        />

                        <ActivityFormInputField
                            items={data?.inputs || []}
                            dataInputs={dataInput || []}
                            isLoading={isLoading || isLoadingInput}
                            isOpen={isOpenInputsDrawer}
                            onOpen={setIsOpenInputsDrawer}
                            onSave={(inputs) =>
                                setData({
                                    ...data,
                                    inputs,
                                })
                            }
                        />

                        <InputText
                            {...getCommonInputStringProps('observation')}
                            label={t('activityFormObservationLabel')}
                            placeholder={t(
                                'activityFormObservationPlaceholder'
                            )}
                        />
                    </ActivityFormInputsWrap>

                    <DrawerFormFooter>
                        <Button model="light" onClick={onCloseDrawer}>
                            {t('activityFormButtonCancel')}
                        </Button>
                        <Button type="submit" isLoading={isLoadingUpdate}>
                            {t('activityFormButtonSave')}
                        </Button>
                    </DrawerFormFooter>
                </ActivityFormContent>
            </form>
        </Drawer>
    );
};
