/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { LatLngBounds, LatLngExpression } from 'leaflet';
import { Polygon, useMap } from 'react-leaflet';
import { ROUTES_AUTH, ROUTES_OWNER_PANEL, trackingEvents } from '@constants';

import { Button, Map } from 'components';
import { useAuth, useLayout, useTracking } from 'contexts';

import { useGetOwnerFieldList } from 'services';
import { Container } from './List.style';

import { ScrollItems } from './components';

export const PanelOwnerFieldList = () => {
    const { idAccess } = useAuth();
    const { initLayout } = useLayout();
    const { trackEvent } = useTracking();

    const [showAllFields, setShowAllFields] = useState(false);
    const [selectedField, setSelectedField] = useState<number>(0);

    const { colors, spacing } = useTheme();

    useEffect(() => {
        initLayout('Áreas de Terra', [
            {
                label: 'Seleção de Conta',
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: 'Áreas de Terra',
                link: ROUTES_OWNER_PANEL.FIELD.fullPath,
            },
        ]);

        trackEvent(trackingEvents.fieldListPageView);
    }, []);


    const { data } = useGetOwnerFieldList({
        filters: {
            idAccess,
        },
    });

    const getCenter = () => {
        const defaultView = { bounds: null, center: [-15.788497, -47.879873], zoom: 4 };

        if (!data?.length) return defaultView;

        const bounds = new LatLngBounds([]);

        try {
            if (showAllFields) {
                let hasValidCoordinates = false;
                
                data.forEach((field) => {
                    try {
                        const coordinates = JSON.parse(field.coordinates);
                        if (!coordinates?.length) return;

                        coordinates.forEach(
                            (point: { latitude: number; longitude: number }) => {
                                if (point?.latitude && point?.longitude) {
                                    bounds.extend([point.latitude, point.longitude]);
                                    hasValidCoordinates = true;
                                }
                            }
                        );
                    } catch (e) {
                        console.warn('Erro ao processar coordenadas do campo:', field.id);
                    }
                });

                return hasValidCoordinates 
                    ? { bounds, center: [-15.788497, -47.879873], zoom: 4 }
                    : defaultView;
            }

            const field = data?.find((item) => item.id === selectedField);
            if (!field) return defaultView;

            try {
                const coordinates = JSON.parse(field.coordinates);
                if (!coordinates?.length) return defaultView;

                let hasValidCoordinates = false;
                coordinates.forEach(
                    (point: { latitude: number; longitude: number }) => {
                        if (point?.latitude && point?.longitude) {
                            bounds.extend([point.latitude, point.longitude]);
                            hasValidCoordinates = true;
                        }
                    }
                );

                return hasValidCoordinates
                    ? { bounds, center: coordinates[0], zoom: 15 }
                    : defaultView;
            } catch (e) {
                return defaultView;
            }
        } catch (error) {
            return defaultView;
        }
    };

    const { bounds, center, zoom } = getCenter();

    const MapController = () => {
        const map = useMap();

        useEffect(() => {
            if (bounds?.isValid()) {
                map.fitBounds(bounds, { padding: [15, 15] });
            }
        }, [map, bounds]);

        return null;
    };

    useEffect(() => {
        if(data && data?.length) {
            setSelectedField(data?.[0]?.id || 0);
        }
    }, [data]);

    return (
        <Container>
            <Button
                onClick={() => setShowAllFields(!showAllFields)}
                variation="secondary"
                size='medium'
                style={{
                    position: 'absolute',
                    top: spacing[4],
                    right: spacing[4],
                    zIndex: 1000,
                }}
            >
                {showAllFields ? 'Mostrar área selecionada' : 'Mostrar todas as áreas'}
            </Button>

            {!showAllFields && <ScrollItems data={data} onSelectField={setSelectedField} selectedField={selectedField} />}

            <Map center={center as LatLngExpression} zoom={zoom}>
                <MapController />
                {
                data &&
                    data?.map((field, index) => {
                        let coordinates = JSON.parse(field.coordinates);
                        coordinates = coordinates?.map((item: any) => ({
                            lat: item.latitude,
                            lng: item.longitude,
                        }));

                        if (!coordinates) return null;
                        try {
                            return (
                                <Polygon
                                key={index}
                                positions={coordinates}
                                pathOptions={{
                                    color: selectedField === field.id || showAllFields? colors.secondary600 : colors.gray600,
                                    opacity: 1,
                                }}
                                />
                            );
                        } catch (error) {
                            return null;
                        }
                    })}
            </Map>
        </Container>
    );
};
