import styled from 'styled-components';

export const InputNumberWrap = styled.div`
    width: 100%;

    .input {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
 
    
        input {
            text-align: center;
        }
        
        button {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            height: 3.5rem;

            svg {
                font-size: ${(props) => props.theme.fontSizes[3]};
                color: ${(props) => props.theme.colors.text.primary};
            }
        }

        button:first-child {
            left: ${(props) => props.theme.spacing[4]};
        }

        button:last-child {
            right: ${(props) => props.theme.spacing[4]};
        }

        button:hover {
            color: ${(props) => props.theme.colors.text.gray900};
        }
    }
`;
