import {convertFloatToBR } from 'utils'
import { Skeleton, SvgMap } from 'components';
import { useAuth } from 'contexts';
import theme from 'styles/theme';

import {
    FieldSummaryWrap,
    FieldSummaryContent,
    FieldSummaryTitle,
    FieldSummaryQuantity,
} from './FieldSummary.style';


import type { FieldSummaryProps } from './FieldSummary.type';

const FieldSummaryLoading = () => {
    return (
        <FieldSummaryWrap>
            <Skeleton height={theme.spacing[14]} width={theme.spacing[14]} />

            <FieldSummaryContent>
                <FieldSummaryTitle>
                    <Skeleton height={theme.fontSizes[7]} width="12rem" />
                </FieldSummaryTitle>

                <FieldSummaryQuantity>
                    <Skeleton height={theme.fontSizes[8]} width="8rem" />
                </FieldSummaryQuantity>
            </FieldSummaryContent>
        </FieldSummaryWrap>
    );
};

const FieldSummaryDefault = ({ item, onEdit }: FieldSummaryProps) => {

    const { account } = useAuth();

    return (
        <FieldSummaryWrap onClick={onEdit}>

            <SvgMap coordinates={item?.coordinates} height={theme.spacing[14]} width={theme.spacing[14]} />

            <FieldSummaryContent>
                <FieldSummaryTitle>{item?.name}</FieldSummaryTitle>

                <FieldSummaryQuantity>
                    {convertFloatToBR(item?.size)} {account?.unitySize === 'METER' ? 'm²' : 'Ha'}
                </FieldSummaryQuantity>
            </FieldSummaryContent>
        </FieldSummaryWrap>
    );
};

export const FieldSummary = ({
    item,
    isLoading,
    onEdit,
}: FieldSummaryProps) => {
    if (isLoading) {
        return <FieldSummaryLoading />;
    }

    return <FieldSummaryDefault item={item} onEdit={onEdit} />;
};
