import { useRef, RefObject } from 'react';
import { ResizableBox as ResizableBoxLib } from 'react-resizable';

import { ResizableBoxWrap } from './styles';

import type { ResizableBoxInterface } from './interface';

const ResizableBox = ({
    children,
    height,
    width = 0,
}: ResizableBoxInterface) => {
    const parentchartRef: RefObject<HTMLDivElement> = useRef(null);

    return (
        <ResizableBoxWrap ref={parentchartRef}>
            <ResizableBoxLib
                width={parentchartRef?.current?.clientWidth || width}
                height={height}
            >
                {children}
            </ResizableBoxLib>
        </ResizableBoxWrap>
    );
};

export default ResizableBox;
