import { CardContentWrap } from './styles';

import type { CardContentInterface } from './interface';

const CardContent = ({ children, noBorder, style }: CardContentInterface) => (
    <CardContentWrap style={style} noBorder={noBorder}>
        {children}
    </CardContentWrap>
);

export default CardContent;
