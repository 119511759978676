import { createContext, useContext, useMemo, useState } from 'react';

import {BreadcrumbsProps, LayoutContextDataProps, LayoutProviderProps } from './LayoutContext.type'

export const LayoutContext = createContext({} as LayoutContextDataProps);

const LayoutProvider = ({ children }: LayoutProviderProps) => {
    const [breadcrumbs, setBreadcrumbs] = useState<
    BreadcrumbsProps[]
    >([]);
    const [title, setTitle] = useState('');

    const initLayout = (titleParam: string, breadcrumbsParam: BreadcrumbsProps[]) => {
        setBreadcrumbs(breadcrumbsParam);
        setTitle(titleParam)
    }

    const valueProvider = useMemo(
        () => (
            { title, onSetTitle: setTitle, breadcrumbs, onSetBreadcrumbs: setBreadcrumbs, initLayout }
        ),
        [title, breadcrumbs]
    );


    return (
        <LayoutContext.Provider
            value={valueProvider}
        >
            {children}
        </LayoutContext.Provider>
    );
};

const useLayout = (): LayoutContextDataProps => {
    const context = useContext(LayoutContext);

    return context;
};

export { LayoutProvider, useLayout };
