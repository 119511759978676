import { useTheme } from 'styled-components';

import {  SvgMap } from 'components';
import { useAuth } from 'contexts';
import { convertFloatToBR } from 'utils';

import { FieldSummaryContent, FieldSummaryQuantity, FieldSummaryTitle, FieldSummaryWrap } from './Item.style';

import type { ItemProps } from './Item.type';

export const Item = ({ data, onSelectField, isSelected }: ItemProps) => {
    const theme = useTheme();

    const { account } = useAuth();

    return (
        <FieldSummaryWrap key={data?.id} onClick={() => onSelectField(data?.id)} isSelected={isSelected}>

        <SvgMap coordinates={data?.coordinates} height={theme.spacing[14]} width={theme.spacing[14]} />

        <FieldSummaryContent>
            <FieldSummaryTitle isSelected={isSelected}>{data?.name}</FieldSummaryTitle>

            <FieldSummaryQuantity isSelected={isSelected}>
                {convertFloatToBR(data?.size)} {account?.unitySize === 'METER' ? 'm²' : 'Ha'}
            </FieldSummaryQuantity>
        </FieldSummaryContent>
    </FieldSummaryWrap>
    )
}