import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiPlus } from 'react-icons/bi';
import * as Yup from 'yup';

import { Button, Drawer, DrawerFormFooter, Select, InputNumber } from 'components';
import { useTracking } from 'contexts';
import { IAActivityHasPatrimony } from '@types';
import { onValidationSchemaObject } from 'helpers';
import {
    trackingEvents,
} from '@constants';

import {
    PatrimonyFieldWrap,
    PatrimonyFieldLabel,
    PatrimonyFieldMoreButton,
    PatrimonyFieldList,
    PatrimonyFieldForm,
    PatrimonyFieldFormTitle,
} from './PatrimonyField.style';

import { PatrimonySummary } from './components';

import type { ActivityFormPatrimonyFieldProps } from './PatrimonyField.type';

export const ActivityFormPatrimonyField = ({
    items,
    dataPatrimonies,
    isLoading,
    isOpen,
    onOpen,
    onSave,
}: ActivityFormPatrimonyFieldProps) => {
    const [editRegister, setEditRegister] = useState<
        IAActivityHasPatrimony | undefined
    >(undefined);
    const [editIndex, setEditIndex] = useState<number | undefined>(undefined);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [listenErrors, setListenErrors] = useState<boolean>(false);

    const { t } = useTranslation();
    const { trackEvent } = useTracking();

    const onChangeValue = (
        key: keyof IAActivityHasPatrimony,
        value: string | number
    ) => {
        const rest: Partial<IAActivityHasPatrimony> = {};

        if (key === 'patrimoniesIds') {
            const patrimonyFinded = dataPatrimonies?.find(
                (patrimony) => patrimony.id === +value
            );

            rest.hasPatrimonies = patrimonyFinded;
            rest.markerInitial = patrimonyFinded?.markerCurrent ? +patrimonyFinded.markerCurrent : undefined;
            rest.markerEnd = patrimonyFinded?.markerCurrent ? +patrimonyFinded.markerCurrent : undefined;
        }

        const newValue = {
            ...editRegister,
            ...rest,
            [key]: value,
        } as IAActivityHasPatrimony;

        setEditRegister(newValue);
    };

    const onNewRegister = () => {
        onOpen(true);
        setEditRegister(undefined);
        setEditIndex(undefined);

        trackEvent(trackingEvents.activityFormPatrimonyAddItemClick);
    };

    const onEditRegister = (index: number) => {
        setEditRegister(items[index]);
        setEditIndex(index);
        
        onOpen(true);
        trackEvent(trackingEvents.activityFormPatrimonyEditItemClick);
    };

    const shapeSchema = {
        patrimoniesIds: Yup.string().required(t('activityFormRequiredField')),
    };
    
    const onSubmitSave = async () => {
        const result = await onValidationSchemaObject(shapeSchema, editRegister);
    
        if (!result.isValid) {
            setErrors(result.errors);
            setListenErrors(true);
            return;
        }
    
        if (editRegister) {
            const isUpdateRegister = editIndex !== undefined;
    
            if (isUpdateRegister) {
                const newItems = items.map((item, index) => {
                    if (index === editIndex) {
                        return editRegister;
                    }
                    return item;
                });
                onSave(newItems);
            } else {
                onSave([...items, editRegister]);
            }
    
            setEditIndex(undefined);
            setEditRegister(undefined);
            onOpen(false);
            setListenErrors(false);
        }
    
        trackEvent(trackingEvents.activityFormPatrimonySaveItemClick);
    };
    

    const onRemoveRegister = (index?: number) => {

        if (index) {
            const copyItems = [...items];
            copyItems.splice(index, 1);

            onSave(copyItems);
            onOpen(false);
            setListenErrors(false);

            trackEvent(trackingEvents.activityFormPatrimonyRemoveItemClick);
        }

    };

    useEffect(() => {
        const updateErrors = async () => {
            if (listenErrors) {
                const result = await onValidationSchemaObject(
                    shapeSchema,
                    editRegister
                );

                setErrors(result.errors);
            }
        };

        updateErrors();
    }, [listenErrors, editRegister]);

    const list = useMemo(() => {
        if (isLoading) {
            return <PatrimonySummary isLoading onEdit={() => null} />;
        }

        return items?.map((item, index) => (
            <PatrimonySummary
                key={index}
                item={item}
                onEdit={() => onEditRegister(index)}
            />
        ));
    }, [items, isLoading]);

    return (
        <PatrimonyFieldWrap>
            <PatrimonyFieldLabel>{t('activityFormPatrimonyLabel')}</PatrimonyFieldLabel>

            <PatrimonyFieldList>{list}</PatrimonyFieldList>

            <PatrimonyFieldMoreButton onClick={onNewRegister}>
                <BiPlus /> {t('activityFormPatrimonyNew')}
            </PatrimonyFieldMoreButton>

            <Drawer
                isOpen={isOpen}
                onOpen={onOpen}
                position="absolute"
                showCloseButton={false}
            >
                <PatrimonyFieldForm>
                    <PatrimonyFieldFormTitle>
                        {editIndex !== null
                            ? t('activityFormPatrimonyEdit')
                            : t('activityFormPatrimonyNew')}
                    </PatrimonyFieldFormTitle>
                    <Select
                        label={t('activityFormPatrimonySelectLabel')}
                        placeholder={t('activityFormPatrimonySelectPlaceholder')}
                        isRequired
                        name="patrimoniesIds"
                        onChange={(newValue) =>
                            onChangeValue('patrimoniesIds', newValue)
                        }
                        value={
                            editRegister?.patrimoniesIds
                                ? `${editRegister?.patrimoniesIds}`
                                : ''
                        }
                        options={
                            dataPatrimonies?.map((patrimony) => ({
                                label: patrimony.name,
                                value: `${patrimony.id}`,
                            })) || []
                        }
                        error={errors?.patrimoniesIds}
                    />

                    <InputNumber
                        label={t('activityFormPatrimonyMarkerInitialLabel')}
                        placeholder={t('activityFormPatrimonyMarkerInitialPlaceholder')}
                        isRequired
                        name="markerInitial"
                        onChange={(newValue) =>
                            onChangeValue('markerInitial', newValue)
                        }
                        value={editRegister?.markerInitial}
                    />

                    <InputNumber
                        label={t('activityFormPatrimonyMarkerEndLabel')}
                        placeholder={t('activityFormPatrimonyMarkerEndPlaceholder')}
                        isRequired
                        name="markerEnd"
                        onChange={(newValue) =>
                            onChangeValue('markerEnd', newValue)
                        }
                        value={editRegister?.markerEnd}
                    />

                    <DrawerFormFooter>
                        {editRegister && (
                            <Button
                                variation="danger"
                                onClick={() => onRemoveRegister(editIndex)}
                                style={{ marginRight: 'auto' }}
                            >
                                {t('activityFormPatrimonyButtonDelete')}
                            </Button>
                        )}
                        <Button variation="light" onClick={() => onOpen(false)}>
                            {t('activityFormPatrimonyButtonBack')}
                        </Button>
                        <Button onClick={onSubmitSave}>
                            {t('activityFormPatrimonyButtonNext')}
                        </Button>
                    </DrawerFormFooter>
                </PatrimonyFieldForm>
            </Drawer>
        </PatrimonyFieldWrap>
    );
};
