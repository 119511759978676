import styled from 'styled-components';

export const TableComponentWrap = styled.div`
    width: 100%;

    .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: ${(props) => props.theme.spacing[4]};
        width: 100%;

        ul.pagination {
            li.page-item {
                margin-right: ${(props) => props.theme.spacing[2]};
                display: inline-block;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: ${(props) => props.theme.spacing[10]};
                    width: ${(props) => props.theme.spacing[10]};

                    font-weight: 400;
                    font-size: ${(props) => props.theme.fontSizes[7]};
                    line-height: 140%;
                    text-decoration: none;
                    text-align: center;
                    color: ${(props) => props.theme.colors.text.gray700};
                    border-radius: ${(props) =>
                        props.theme.borderRadius.default};
                    svg {
                        font-size: ${(props) => props.theme.fontSizes[9]};
                    }
                }

                &.active {
                    button {
                        background-color: ${(props) =>
                            props.theme.colors.base.primary};
                        color: ${(props) => props.theme.colors.base.white};
                    }
                }

                &:hover {
                    button {
                        background-color: ${(props) =>
                            props.theme.colors.base.primary};
                        color: ${(props) => props.theme.colors.base.secondary};
                    }
                }
            }
        }

        .box-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            select {
                display: flex;
                align-items: center;
                justify-content: center;

                font-weight: 400;
                font-size: ${(props) => props.theme.fontSizes[7]};
                line-height: 140%;
                text-decoration: none;
                text-align: center;
                color: ${(props) => props.theme.colors.text.gray700};
                margin-left: ${(props) => props.theme.spacing[4]};
                border: none;

                &:focus {
                    outline: 0;
                }
            }

            .description {
                font-size: ${(props) => props.theme.fontSizes[7]};
                font-weight: 400;
                color: ${(props) => props.theme.colors.text.gray700};
            }
        }
    }
`;
