export const unityMetricCrops = [
      {
        value: 'TONNES',
        operation: 'DIV',
        operationValue: 1000,
      },
      {
        value: 'BAGS60',
        operation: 'DIV',
        operationValue: 60,
      },
      {
        value: 'BAGS50',
        operation: 'DIV',
        operationValue: 50,
      },
      {
        value: 'BAGS40',
        operation: 'DIV',
        operationValue: 40,
      },
      {
        value: 'BAGS30',
        operation: 'DIV',
        operationValue: 30,
      },
      {
        value: 'KILOGRAMS',
        operation: 'MUL',
        operationValue: 1,
      },
      { value: 'GRAMS', operation: 'MUL', operationValue: 1000 },
      {

        value: 'MILLIGRAMS',
        operation: 'MUL',
        operationValue: 10000,
      },
      {
        value: 'BOXES40.8',
        operation: 'DIV',
        operationValue: 40.8,
      },
      {
        value: 'UNITS',
        operation: 'MUL',
        operationValue: 1,
      }
    ];
  
  