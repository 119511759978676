import styled from 'styled-components';

export const ContentWrap = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    h3 {
        font-size: ${(props) => props.theme.fontSizes[1]} !important;
        font-weight: 400;
        line-height: 1.2;
        color: ${(props) => props.theme.colors.base.white};

        margin-bottom: ${(props) => props.theme.spacing[5]};
    }

    p {
        font-size: ${(props) => props.theme.fontSizes[7]};
        font-weight: 300;
        line-height: 1.5;
        color: rgba(255, 255, 255, 0.8);
    }
`;
