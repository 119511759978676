import { TabsWrap } from './styles';

import type { TabsInterface } from './interface';

const Tabs = ({ items, onChange, value }: TabsInterface) => (
    <TabsWrap>
        {items?.map((item) => (
            <li
                key={item.value}
                className={item.value === value ? 'active' : ''}
            >
                <button
                    className={item.value === value ? 'active' : ''}
                    type="button"
                    onClick={() => onChange(item.value)}
                >
                    {item.label}
                </button>
            </li>
        ))}
    </TabsWrap>
);

export default Tabs;
