import { Link } from 'react-router-dom';

import { TitleWrap } from './styles';

import type { TitleInterface } from './interface';

const Title = ({ className, title, breadcrumbs }: TitleInterface) => (
    <TitleWrap
        className={className}
        haveBreadCrumbs={breadcrumbs?.length !== 0}
    >
        <h1>{title}</h1>
        <ul>
            {breadcrumbs?.map(({ label, link }, index) => (
                <li key={label}>
                    <Link to={link}>{label} </Link>
                    <span>
                        {Number(breadcrumbs?.length || 0) - 1 === index
                            ? ''
                            : '/'}
                    </span>
                </li>
            ))}
        </ul>
    </TitleWrap>
);

export default Title;
