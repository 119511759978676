import { Button } from 'components';

import { ResponsiveMenuWrap } from './styles';

import type { ResponsiveMenuInterface } from './interface';

const ResponsiveMenu = ({
    menus,
    isOpenResponsiveMenu,
}: ResponsiveMenuInterface) => (
    <ResponsiveMenuWrap
        className={isOpenResponsiveMenu ? 'is-open-responsive-menu' : ''}
    >
        {menus?.map(({ active, label, link }) => (
            <li key={label}>
                <Button
                    variation="white"
                    href={link}
                    className={active ? 'active' : ''}
                >
                    {label}
                </Button>
            </li>
        ))}
    </ResponsiveMenuWrap>
);

export default ResponsiveMenu;
