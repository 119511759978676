import styled, { css } from 'styled-components';

import type { CardInfoInterface } from './interface';

interface CardInfoWrapInterface
    extends Pick<CardInfoInterface, 'variation' | 'model'> { }

const variations = {
    base: {
        primary: css`
            background-color: ${(props) => props.theme.colors.base.primary};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        secondary: css`
            background-color: ${(props) => props.theme.colors.base.secondary};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        info: css`
            background-color: ${(props) => props.theme.colors.base.info};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        dark: css`
            background-color: ${(props) => props.theme.colors.base.dark};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        danger: css`
            background-color: ${(props) => props.theme.colors.base.danger};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        warning: css`
            background-color: ${(props) => props.theme.colors.base.warning};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
        success: css`
            background-color: ${(props) => props.theme.colors.base.success};

            svg {
                color: ${(props) => props.theme.colors.base.white};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.white};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.white};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.white};
            }
        `,
    },
    light: {
        primary: css`
            background-color: ${(props) => props.theme.colors.light.primary};

            svg {
                color: ${(props) => props.theme.colors.base.primary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.primary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.primary};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        secondary: css`
            background-color: ${(props) => props.theme.colors.light.secondary};

            svg {
                color: ${(props) => props.theme.colors.base.primary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.primary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.primary};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        info: css`
            background-color: ${(props) => props.theme.colors.light.info};

            svg {
                color: ${(props) => props.theme.colors.base.info};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.info};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.info};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        dark: css`
            background-color: ${(props) => props.theme.colors.light.dark};

            svg {
                color: ${(props) => props.theme.colors.base.dark};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.dark};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        danger: css`
            background-color: ${(props) => props.theme.colors.light.danger};

            svg {
                color: ${(props) => props.theme.colors.base.danger};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.danger};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.danger};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        warning: css`
            background-color: ${(props) => props.theme.colors.light.warning};

            svg {
                color: ${(props) => props.theme.colors.base.warning};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.warning};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.warning};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        success: css`
            background-color: ${(props) => props.theme.colors.light.success};

            svg {
                color: ${(props) => props.theme.colors.base.secondary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.secondary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.base.secondary};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
    },
    white: {
        primary: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.primary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.primary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        secondary: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.secondary};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.secondary};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        info: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.info};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.info};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        dark: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.dark};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.dark};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        danger: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.danger};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.danger};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        warning: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.warning};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.warning};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
        success: css`
            background-color: ${(props) => props.theme.colors.base.white};

            svg {
                color: ${(props) => props.theme.colors.base.success};
                g [fill] {
                    fill: ${(props) => props.theme.colors.base.success};
                }
            }

            h3 {
                color: ${(props) => props.theme.colors.text.dark};
            }

            h4 {
                color: ${(props) => props.theme.colors.text.gray400};
            }
        `,
    },
};

export const CardInfoWrap = styled.div<CardInfoWrapInterface>`
    position: relative;
    display: flex;

    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: ${(props) => props.theme.borderRadius.large};
    border: 0;
    box-shadow: 0px 0px 20px 0px ${(props) => props.theme.colors.shadow.small};

    width: 100%;

    padding: ${(props) => props.theme.spacing[8]}
        ${(props) => props.theme.spacing[9]};

    svg {
        height: 2.75rem;
        width: 2.75rem;
    }

    .number {
        font-size: ${(props) => props.theme.fontSizes[2]};
        font-weight: 700;
        margin-top: ${(props) => props.theme.spacing[5]};
        margin-bottom: ${(props) => props.theme.spacing[2]};
    }

    .description {
        font-size: ${(props) => props.theme.fontSizes.base};
        font-weight: 400;
    }

    ${(props) =>
        variations[props.model || 'white'][props.variation || 'primary']}
`;
