import { Select, Skeleton } from 'components';

import { ListWrap } from './styles';

import type { ListInterface } from './interface';

const List = ({
    columns,
    data,
    sortObject,
    onSetSortObject,
    isLoading
}: ListInterface) => {
    const columnsWithSort = columns.filter((column) => column.sort);

    const onHandleSort = (newValue: string) => {
        if (newValue.includes('_')) {
            const [key, order] = newValue.split('_');

            onSetSortObject({
                key,
                order,
            });
        }

        return null;
    };

    const getOrder = () => {
        if (sortObject?.key) {
            return `${sortObject?.key}_${sortObject?.order}`;
        }

        return '';
    };

    const getSort = () => {
        if (columnsWithSort?.length > 0) {
            return (
                <Select
                    name="sort"
                    options={columnsWithSort.flatMap((column) => [
                        {
                            label: `Ordenando por ${column.text} Crescente`,
                            value: `${column.dataField}_ASC`,
                        },
                        {
                            label: `Ordenando por ${column.text} Decrescente`,
                            value: `${column.dataField}_DESC`,
                        },
                    ])}
                    onChange={onHandleSort}
                    value={getOrder()}
                    placeholder="Ordenar por"
                />
            );
        }

        return null;
    };

    return (
        <ListWrap>
            {getSort()}
            <ul>
                {data?.map((row, indexRow) => {
                    const keyRow = `index_row_${indexRow}`;

                    return (
                        <li key={keyRow}>
                            {columns.map((column, indexColumn) => {
                                const keyColumn = `index_column_${indexColumn}`;

                                if (isLoading) {
                                    return (
                                        <div key={keyColumn}>
                                            <Skeleton height="1.3rem" />
                                        </div>
                                    );
                                }


                                return (
                                    <div key={keyColumn}>
                                        {column.formatter
                                            ? column.formatter(
                                                  row[column.dataField],
                                                  indexRow,
                                                  row
                                              )
                                            : row[column.dataField]}
                                    </div>
                                );
                            })}
                        </li>
                    );
                })}
            </ul>
        </ListWrap>
    );
};

export default List;
