/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Card, Grid } from 'components';
import { useAuth, useLayout, useTracking } from 'contexts';
import {
    QUERIES,
    ROUTES_AUTH,
    ROUTES_OWNER_PANEL,
    trackingEvents,
} from '@constants';
import { useGetOwnerCropList } from 'services';

import { CropShow, CropTable } from '../components';

const CropList = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { idAccess } = useAuth();
    const { initLayout } = useLayout();
    const { trackEvent } = useTracking();

    useEffect(() => {
        initLayout(t('routesCrop'), [
            {
                label: t('sidebarSelectAccount'),
                link: ROUTES_AUTH.ACCOUNT_SELECTED.fullPath,
            },
            {
                label: t('cropListTitle'),
                link: ROUTES_OWNER_PANEL.CROP.fullPath,
            },
        ]);

        trackEvent(trackingEvents.cropListPageView);
    }, []);

    const [isOpenModalRegister, setIsOpenModalRegister] = useState(false);
    const [idRegister, setIdRegister] = useState('');

    const handleOpenModal = (isOpen: boolean) => {
        if (!isOpen) {
            trackEvent(trackingEvents.activityListCloseRegisterClick);
        }

        setIsOpenModalRegister(isOpen);
    };

    const onNewRegister = () => {
        trackEvent(trackingEvents.activityListNewRegisterClick);

        setIdRegister('');
        setIsOpenModalRegister(true);
    };

    const onSelectRegister = (id: string) => {
        trackEvent(trackingEvents.activityListShowRegisterClick, { id });

        setIdRegister(id);
        setIsOpenModalRegister(true);
    };

    const { data, isLoading, refetch } = useGetOwnerCropList({
        filters: { idAccess },
    })

    return (
        <>
            <Grid>
                <Card
                    headerTitle={t('cropListHeaderTitle')}
                    headerSubTitle={t('cropListHeaderSubTitle')}
                    // headerToolbar={
                    // <Button onClick={onNewRegister} model="light">
                    //     {t('activityListButtonNewRegister')}
                    // </Button>
                    // }
                >
                    <CropTable
                        data={data}
                        isLoading={isLoading}
                        onSelectRegister={onSelectRegister}
                        onRefetch={refetch}
                    />
                </Card>
            </Grid>

            <CropShow
                id={idRegister}
                onOpen={handleOpenModal}
                isOpen={isOpenModalRegister}
            />
        </>
    );
};

export default CropList;
