import { useEffect, useRef } from 'react';

import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';

import { Container, ScrollContainer,  ScrollButton } from './ScrollItems.style';
import { Item } from '../Item';
import type { ScrollItemsProps } from './ScrollItems.type';

export const ScrollItems = ({ data, onSelectField, selectedField }: ScrollItemsProps) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const selectPreviousField = () => {
        const findedIndex = data?.findIndex((item) => item.id === selectedField);


        if (findedIndex && findedIndex > 0) {
            onSelectField(data?.[findedIndex - 1]?.id || 0);

            return
        }

        onSelectField(data?.[data?.length - 1]?.id || 0); // eslint-disable-line
    }

    const selectNextField = () => {
        const findedIndex = data?.findIndex((item) => item.id === selectedField);

        if (findedIndex && findedIndex < (data?.length && Array.isArray(data) ? data.length : 1) - 1) {
          
            onSelectField(data?.[findedIndex + 1]?.id || 0); // eslint-disable-line

            return
        }


        onSelectField(data?.[1]?.id || data?.[0]?.id || 0); // eslint-disable-line
    }

    useEffect(() => {
        if (selectedField) {
            const findedIndex = data?.findIndex((item) => item.id === selectedField);

            const scrollContainerWidth = scrollRef.current?.offsetWidth;
            const itemWidth = scrollRef.current?.children[0]?.clientWidth || 0;
            const gap = 16; 

            if (findedIndex !== undefined && findedIndex >= 0 && scrollContainerWidth) {
                const scrollPosition = (itemWidth + gap) * findedIndex;
                scrollRef.current?.scrollTo({ 
                    left: scrollPosition, 
                    behavior: 'smooth' 
                });
            }
        }
    }, [selectedField, data]);

    return (
        <Container>
            <ScrollButton className="prev" onClick={selectPreviousField}>
                <BiChevronLeft />
            </ScrollButton>

            <ScrollContainer ref={scrollRef}>
                {data?.map((item) => (
                    <Item key={item.id} data={item} onSelectField={onSelectField} 
                        isSelected={selectedField === item?.id}
                    />
                ))}
            </ScrollContainer>

            <ScrollButton className="next" onClick={selectNextField}>
                <BiChevronRight />
            </ScrollButton>
        </Container>
    );
};
