import styled, { css } from 'styled-components';

import type { BadgeInterface } from './interface';

interface BadgeWrapInterface
    extends Pick<
        BadgeInterface,
        'variation' | 'size' | 'model' | 'isCircle' | 'isSquare'
    > {}

const base = {
    primary: css`
        color: ${(props) => props.theme.colors.text.white};
        background-color: ${(props) => props.theme.colors.base.primary};

        svg {
            fill: ${(props) => props.theme.colors.text.white};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.primary};
        }
    `,
    secondary: css`
        color: ${(props) => props.theme.colors.base.primary};
        background-color: ${(props) => props.theme.colors.base.secondary};

        svg {
            fill: ${(props) => props.theme.colors.base.primary};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.secondary};
        }
    `,
    white: css`
        color: ${(props) => props.theme.colors.text.primary};
        background-color: ${(props) => props.theme.colors.base.white};

        svg {
            fill: ${(props) => props.theme.colors.text.primary};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.white};
        }
    `,
    light: css`
        color: ${(props) => props.theme.colors.text.primary};
        background-color: ${(props) => props.theme.colors.base.light};

        svg {
            fill: ${(props) => props.theme.colors.text.primary};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.light};
        }
    `,
    dark: css`
        color: ${(props) => props.theme.colors.text.white};
        background-color: ${(props) => props.theme.colors.base.dark};

        svg {
            fill: ${(props) => props.theme.colors.text.white};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.dark};
        }
    `,
    info: css`
        color: ${(props) => props.theme.colors.text.white};
        background-color: ${(props) => props.theme.colors.base.info};

        svg {
            fill: ${(props) => props.theme.colors.text.info};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.info};
        }
    `,
    success: css`
        color: ${(props) => props.theme.colors.text.white};
        background-color: ${(props) => props.theme.colors.base.success};

        svg {
            fill: ${(props) => props.theme.colors.text.success};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.success};
        }
    `,
    warning: css`
        color: ${(props) => props.theme.colors.text.white};
        background-color: ${(props) => props.theme.colors.base.warning};

        svg {
            fill: ${(props) => props.theme.colors.text.warning};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.warning};
        }
    `,
    danger: css`
        color: ${(props) => props.theme.colors.text.white};
        background-color: ${(props) => props.theme.colors.base.danger};

        svg {
            fill: ${(props) => props.theme.colors.text.danger};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.danger};
        }
    `,
};

const light = {
    primary: css`
        color: ${(props) => props.theme.colors.base.primary};
        background-color: ${(props) => props.theme.colors.light.primary};

        svg {
            fill: ${(props) => props.theme.colors.base.primary};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.primary};
            color: ${(props) => props.theme.colors.text.white};
            fill: ${(props) => props.theme.colors.text.white};
        }
    `,
    secondary: css`
        color: ${(props) => props.theme.colors.base.secondary};
        background-color: ${(props) => props.theme.colors.light.secondary};

        svg {
            fill: ${(props) => props.theme.colors.base.secondary};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.base.secondary};
            color: ${(props) => props.theme.colors.text.white};
            fill: ${(props) => props.theme.colors.text.white};
        }
    `,
    white: css`
        color: ${(props) => props.theme.colors.text.primary};
        background-color: ${(props) => props.theme.colors.light.white};

        svg {
            fill: ${(props) => props.theme.colors.text.primary};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.white};
        }
    `,
    light: css`
        color: ${(props) => props.theme.colors.text.primary};
        background-color: ${(props) => props.theme.colors.light.light};

        svg {
            fill: ${(props) => props.theme.colors.text.primary};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.active.light};
        }
    `,
    dark: css`
        color: ${(props) => props.theme.colors.base.dark};
        background-color: ${(props) => props.theme.colors.light.dark};

        svg {
            fill: ${(props) => props.theme.colors.base.dark};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.base.dark};
            color: ${(props) => props.theme.colors.text.white};
            fill: ${(props) => props.theme.colors.text.white};
        }
    `,
    info: css`
        color: ${(props) => props.theme.colors.base.info};
        background-color: ${(props) => props.theme.colors.light.info};

        svg {
            fill: ${(props) => props.theme.colors.base.info};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.base.info};
            color: ${(props) => props.theme.colors.text.white};
            fill: ${(props) => props.theme.colors.text.white};
        }
    `,
    success: css`
        color: ${(props) => props.theme.colors.active.success};
        background-color: ${(props) => props.theme.colors.light.success};

        svg {
            fill: ${(props) => props.theme.colors.active.success};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.base.success};
            color: ${(props) => props.theme.colors.text.white};
            fill: ${(props) => props.theme.colors.text.white};
        }
    `,
    warning: css`
        color: ${(props) => props.theme.colors.base.warning};
        background-color: ${(props) => props.theme.colors.light.warning};

        svg {
            fill: ${(props) => props.theme.colors.base.warning};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.base.warning};
            color: ${(props) => props.theme.colors.text.white};
            fill: ${(props) => props.theme.colors.text.white};
        }
    `,
    danger: css`
        color: ${(props) => props.theme.colors.base.danger};
        background-color: ${(props) => props.theme.colors.light.danger};

        svg {
            fill: ${(props) => props.theme.colors.base.danger};
        }

        &:hover,
        &.active {
            background-color: ${(props) => props.theme.colors.base.danger};
            color: ${(props) => props.theme.colors.text.white};
            fill: ${(props) => props.theme.colors.text.white};
        }
    `,
};

const model = {
    base,
    light,
};

const sizes = {
    small: css<BadgeWrapInterface>`
        padding: ${(props) => props.theme.spacing[1]}
            ${(props) => props.theme.spacing[2]};
        font-size: ${(props) => props.theme.fontSizes[9]};

        ${(props) =>
            (props.isCircle || props.isSquare) &&
            css`
                padding: 0;
                height: ${props.theme.spacing[6]};
                min-width: ${props.theme.spacing[6]};
            `}
    `,
    normal: css<BadgeWrapInterface>`
        padding: ${(props) => props.theme.spacing[1]}
            ${(props) => props.theme.spacing[2]};
        font-size: ${(props) => props.theme.fontSizes[8]};

        ${(props) =>
            (props.isCircle || props.isSquare) &&
            css`
                padding: 0;
                height: ${props.theme.spacing[7]};
                min-width: ${props.theme.spacing[7]};
            `}
    `,

    large: css<BadgeWrapInterface>`
        padding: ${(props) => props.theme.spacing[1]}
            ${(props) => props.theme.spacing[2]};
        font-size: ${(props) => props.theme.fontSizes[6]};

        ${(props) =>
            (props.isCircle || props.isSquare) &&
            css`
                padding: 0;
                height: ${props.theme.spacing[8]};
                min-width: ${props.theme.spacing[8]};
            `}
    `,
};

export const BadgeWrap = styled.div<BadgeWrapInterface>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 0;

    border-radius: ${(props) => props.theme.borderRadius.default};
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: none !important;
    text-decoration: none;

    ${(props) =>
        props.isCircle &&
        css`
            border-radius: 100%;
        `}

    ${(props) => model[props.model || 'base'][props.variation || 'primary']};

    ${(props) => sizes[props.size || 'normal']}
`;
