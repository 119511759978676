import { GridWrap } from './styles';

import type { GridInterface } from './interface';

const Grid = ({
    children,
    className,
    id,
    numberColumns = 1,
    gridTemplateColumns,
    gridGap,
    style,
}: GridInterface) => (
    <GridWrap
        id={id}
        className={className}
        numberColumns={numberColumns}
        gridTemplateColumns={gridTemplateColumns}
        gridGap={gridGap}
        style={style}
    >
        {children}
    </GridWrap>
);

export default Grid;
