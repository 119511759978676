import styled from 'styled-components';

import { responsivator, transformValuesInStyles } from 'helpers';

import type { GridInterface } from './interface';

interface GridWrapInterface
    extends Pick<
        GridInterface,
        'numberColumns' | 'gridGap' | 'gridTemplateColumns'
    > {}

const getGridTemplateColumns = (
    gridTemplateColumns?: string | string[],
    numberColumns?: number | number[]
) => {
    if (gridTemplateColumns) {
        const newValues = transformValuesInStyles(
            gridTemplateColumns,
            'grid-template-columns: {{value}}'
        );

        return responsivator(newValues);
    }

    const newValues = transformValuesInStyles(
        numberColumns,
        'grid-template-columns: repeat({{value}}, 1fr)'
    );

    return responsivator(newValues);
};

export const GridWrap = styled.div<GridWrapInterface>`
    display: grid;

    width: 100%;
    grid-gap: ${(props) =>
        !props.gridGap ? props.theme.spacing[6] : props.gridGap};

    ${(props) =>
        getGridTemplateColumns(props.gridTemplateColumns, props.numberColumns)};
`;
