import { MapConsumer, MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import type { MapInterface } from './interface';

const MapComp = ({
    children,
    mapConsumer = () => null,
    ...rest
}: MapInterface) => (
    <MapContainer
        style={{ width: '100%', height: '100%' }}
        zoom={15}
        scrollWheelZoom={false}
        {...rest}
    >
        {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
        {/* <TileLayer url="http://ecn.t3.tiles.virtualearth.net/tiles/a{q}.jpeg?g=1" /> */}
        {/* <TileLayer url="http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}" /> */}
        {/* <TileLayer url="https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}" /> */}
        <TileLayer attribution="" url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        {/* <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" /> */}
        {/* <TileLayer url="https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" /> */}
        {/* <TileLayer url="http://tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
        {/* <TileLayer url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png" /> */}

        <MapConsumer>{mapConsumer}</MapConsumer>
        {children}
    </MapContainer>
);

export default MapComp;
