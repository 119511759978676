import styled from 'styled-components';

export const MySearchWrap = styled.div`
    display: flex;
    align-items: center;

    margin-bottom: ${(props) => props.theme.spacing[4]};

    button {
        margin-left: ${(props) => props.theme.spacing[4]};
    }
`;
