import { FooterWrap } from './styles';

const Footer = () => (
    <FooterWrap>
        <span>© {new Date().getFullYear()} Agtor</span>

        <div>
            <a href="#termos">Termos de Privacidade</a>
            <a href="#contato">Contato</a>
        </div>
    </FooterWrap>
);

export default Footer;
