import styled from 'styled-components';

export const FooterWrap = styled.footer`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    span {
        font-size: ${(props) => props.theme.fontSizes[7]};
        line-height: 1;
        font-weight: 400;
        color: ${(props) => props.theme.colors.text.gray200};
    }

    a {
        font-size: ${(props) => props.theme.fontSizes[7]};

        line-height: 1;
        font-weight: 300;
        color: ${(props) => props.theme.colors.base.secondary};

        margin-left: ${(props) => props.theme.spacing[5]};
    }
`;
