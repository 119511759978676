import { Outlet } from 'react-router-dom';

import logo from 'assets/images/logo_vertical.png';
import { Grid } from 'components';

import Content from './Content';
import Footer from './Footer';
import { AuthWrap } from './styles';

const Auth = () => (
    <AuthWrap>
        <Grid
            gridTemplateColumns={['2fr 3fr', '2fr 3fr', '2fr 3fr', '1fr']}
            gridGap="0"
        >
            <div className="column-info">
                <img src={logo} className="logo" alt="Logo Agtor" />
                <Content />

                <Footer />
            </div>

            <Outlet />
        </Grid>
    </AuthWrap>
);

export default Auth;
